import { setLanguageFile , trans as cisTrans } from "@clickbar/ai18n"
import { router } from "@inertiajs/vue3"
import { useTextDirection } from "@vueuse/core"
import { ref } from "vue"

const currentLanguageCode = ref('de')

const langs = import.meta.glob('../../lang/*.json')

export async function loadLanguageFile(languageCode: App.Enums.Locale) {
  const languageFile = await langs[`../../lang/${languageCode}.json`]() as { default: Record<string, string> }
  setLanguageFile(languageCode, languageFile.default)
  currentLanguageCode.value = languageCode
}

export function trans(key: string, replacements?: Record<string, string | number> & { n?: number }) {
  // we just want to trigger the getter.
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  currentLanguageCode.value
  return cisTrans(key, replacements)
}

export const textDirection = useTextDirection()

export function setLocale(locale: App.Enums.Locale) {
  void loadLanguageFile(locale)
  router.post('/locale', { locale })

  textDirection.value = locale === 'ar' ? 'rtl' : 'ltr'
}
